import { useAuthenticationContext } from 'components/providers/authentication-provider';
import { ACCOUNT_DOMAIN, SOCIAL_DOMAIN } from 'globalConstants';
import { isEqual, map } from 'lodash';
import Link from 'next/link';
import { Fragment } from 'react';
import { CircleFlag } from 'react-circle-flags';
import { CgFacebook } from 'react-icons/cg';
import { IoCall } from 'react-icons/io5';
import { MdMail, MdOutlineArrowDropDown } from 'react-icons/md';
import { SiTiktok, SiTwitter, SiYoutube } from 'react-icons/si';
import { TiLocation } from 'react-icons/ti';

const FACEBOOK_DOMAIN = 'https://www.facebook.com/atmnha/';
const YOUTUBE_DOMAIN = 'https://www.youtube.com/channel/UCawniMq-oiML35r6E7aNAFQ';
const TIKTOK_DOMAIN = 'https://www.tiktok.com/@atmnha';
const TWITTER_DOMAIN = 'https://twitter.com/atmnhavietnam';

const Footer = () => {
  const { currentUser } = useAuthenticationContext();
  const navigations = [
    {
      name: 'Bán',
      children: [
        {
          name: 'Nhà mặt phố',
          pathname: '/ban-nha-mat-pho',
        },
        {
          name: 'Nhà riêng',
          pathname: '/ban-nha-rieng',
        },
        {
          name: 'Biệt thự, nhà liền kề',
          pathname: '/ban-biet-thu-nha-lien-ke',
        },
        {
          name: 'Cao ốc, văn phòng',
          pathname: '/ban-cao-oc-van-phong',
        },
        {
          name: 'Khách sạn',
          pathname: '/ban-khach-san',
        },
        {
          name: 'Nhà xưởng, kho, kiot',
          pathname: '/ban-nha-xuong-kho-kiot',
        },
        {
          name: 'Đất nền',
          pathname: '/ban-dat-nen',
        },
        {
          name: 'Căn hộ chung cư',
          pathname: '/ban-can-ho-chung-cu',
        },
        {
          name: 'Shophouse',
          pathname: '/ban-shophouse',
        },
        {
          name: 'Penthouse',
          pathname: '/ban-penthouse',
        },
        {
          name: 'Condotel',
          pathname: '/ban-condotel',
        },
        {
          name: 'Trang trại, khu nghỉ dưỡng',
          pathname: '/ban-trang-trai-khu-nghi-duong',
        },
        {
          name: 'Nhà đất khác',
          pathname: '/ban-nha-dat-khac',
        },
      ],
    },
    {
      name: 'Cho thuê',
      children: [
        {
          name: 'Nhà mặt phố',
          pathname: '/cho-thue-nha-mat-pho',
          extraProps: {},
        },
        {
          name: 'Nhà riêng',
          pathname: '/cho-thue-nha-rieng',
          extraProps: {},
        },
        {
          name: 'Biệt thự, nhà liền kề',
          pathname: '/cho-thue-biet-thu-nha-lien-ke',
          extraProps: {},
        },
        {
          name: 'Cao ốc, văn phòng',
          pathname: '/cho-thue-cao-oc-van-phong',
          extraProps: {},
        },
        {
          name: 'Khách sạn',
          pathname: '/cho-thue-khach-san',
          extraProps: {},
        },
        {
          name: 'Nhà xưởng, kho, kiot',
          pathname: '/cho-thue-nha-xuong-kho-kiot',
          extraProps: {},
        },
        {
          name: 'Đất nền',
          pathname: '/cho-thue-dat-nen',
          extraProps: {},
        },
        {
          name: 'Căn hộ chung cư',
          pathname: '/cho-thue-can-ho-chung-cu',
          extraProps: {},
        },
        {
          name: 'Shophouse',
          pathname: '/cho-thue-shophouse',
          extraProps: {},
        },
        {
          name: 'Penthouse',
          pathname: '/cho-thue-penthouse',
          extraProps: {},
        },
        {
          name: 'Condotel',
          pathname: '/cho-thue-condotel',
          extraProps: {},
        },
        {
          name: 'Phòng trọ',
          pathname: '/cho-thue-phong-tro',
          extraProps: {},
        },
        {
          name: 'Nhà đất khác',
          pathname: '/cho-thue-nha-dat-khac',
          extraProps: {},
        },
      ],
    },
    {
      name: 'Dịch vụ',
      children: [
        {
          name: 'Định giá',
          pathname: '/dinh-gia',
        },
        {
          name: 'Bản đồ thế giới',
          pathname: '/ban-do',
        },
        {
          name: 'Bản đồ quy hoạch',
          pathname: '/ban-do?mapType=internal',
        },
        {
          name: 'Mạng lưới thành viên',
          pathname: currentUser
            ? `${ACCOUNT_DOMAIN}/mang-luoi-cua-toi`
            : `${ACCOUNT_DOMAIN}/?tab=dangnhap&redirectUrl=${ACCOUNT_DOMAIN}/mang-luoi-cua-toi`,
        },
        {
          name: 'Lịch nhắc hẹn',
          pathname: currentUser
            ? `${ACCOUNT_DOMAIN}/lich-xem-nha`
            : `${ACCOUNT_DOMAIN}/?tab=dangnhap&redirectUrl=${ACCOUNT_DOMAIN}/lich-xem-nha`,
        },
        {
          name: 'Mạng xã hội',
          pathname: SOCIAL_DOMAIN,
        },
      ],
    },
  ];
  const stores = [
    {
      background: 'bg-[url("/logos/google-play.svg")]',
      href: 'https://play.google.com/store/apps/details?id=com.app.realtech247.atmnha.prod',
    },
    {
      background: 'bg-[url("/logos/app-store.svg")]',
      href: 'https://apps.apple.com/vn/app/atm-nh%C3%A0/id6443934765?l=vi',
    },
  ];
  const socials = [
    {
      icon: <CgFacebook className='min-h-[15px] min-w-[15px]' />,
      href: FACEBOOK_DOMAIN,
    },
    {
      icon: <SiYoutube className='min-h-[15px] min-w-[15px]' />,
      href: YOUTUBE_DOMAIN,
    },
    {
      icon: <SiTiktok className='min-h-[15px] min-w-[15px]' />,
      href: TIKTOK_DOMAIN,
    },
    {
      icon: <SiTwitter className='min-h-[15px] min-w-[15px]' />,
      href: TWITTER_DOMAIN,
    },
  ];
  const stack = [
    {
      label: 'Giới thiệu',
      href: `${ACCOUNT_DOMAIN}/thong-tin-chung#gioi-thieu`,
    },
    {
      label: 'Các dịch vụ chính',
      href: `${ACCOUNT_DOMAIN}/thong-tin-chung#cac-dich-vu-chinh`,
    },
    {
      label: 'Liên hệ',
      href: `${ACCOUNT_DOMAIN}/thong-tin-chung#lien-he`,
    },
    {
      label: 'Báo giá và hỗ trợ',
      href: `${ACCOUNT_DOMAIN}/thong-tin-chung#bao-gia-va-ho-tro`,
    },
    {
      label: 'Những câu hỏi thường gặp',
      href: `${ACCOUNT_DOMAIN}/thong-tin-chung#nhung-cau-hoi-thuong-gap`,
    },
    {
      label: 'Điều khoản thoả thuận',
      href: `${ACCOUNT_DOMAIN}/thong-tin-chung#dieu-khoan-thoa-thuan`,
    },
    {
      label: 'Trách nhiệm và nghĩa vụ của người sử dụng',
      href: `${ACCOUNT_DOMAIN}/thong-tin-chung#trach-nhiem-va-nghia-vu-cua-nguoi-su-dung`,
    },
    {
      label: 'Sở hữu trí tuệ',
      href: `${ACCOUNT_DOMAIN}/thong-tin-chung#so-huu-tri-tue`,
    },
    {
      label: 'Chính sách bảo mật',
      href: `${ACCOUNT_DOMAIN}/thong-tin-chung#chinh-sach-bao-mat`,
    },
    {
      label: 'Xác nhập hoặc nhượng quyền',
      href: `${ACCOUNT_DOMAIN}/thong-tin-chung#nhuong-quyen`,
    },
    {
      label: 'Cơ chế giải quyết khiếu nại',
      href: `${ACCOUNT_DOMAIN}/thong-tin-chung#co-che-giai-quyet-khieu-nai`,
    },
    {
      label: 'Quy định khi đăng tin và quảng cáo',
      href: `${ACCOUNT_DOMAIN}/thong-tin-chung#quy-dinh-khi-dang-tin-va-quang-cao`,
    },
  ];

  return (
    <footer
      id='footer'
      className='min-w-[1052px] bg-[url("/images/footer-background.webp")] bg-cover bg-center bg-no-repeat text-paper'
    >
      <div className='bg-primary opacity-90'>
        <div className='container mx-auto min-w-[1052px] px-[48px] pt-[46px] pb-[16px]'>
          <div className='flex justify-between space-x-[60px] xl:space-x-[20px]'>
            <div className='flex flex-col space-y-[20px] xl:w-1/4'>
              <img src='/logos/atm-nha-white.svg' alt='ATM Nhà' className='h-[40px] w-[132px]' />
              <div className='flex items-center space-x-[8px]'>
                <IoCall className='min-h-[18px] min-w-[18px] ' />
                <div className='flex flex-col'>
                  <span className='text-[13px] leading-[16px]'>Điện thoại</span>
                  <span className='text-[13px] font-[600] leading-[16px]'>0789 333 322</span>
                </div>
              </div>
              <div className='flex items-center space-x-[8px]'>
                <MdMail className='min-h-[18px] min-w-[18px] ' />
                <div className='flex flex-col'>
                  <span className='text-[13px] leading-[16px]'>Email</span>
                  <span className='text-[13px] font-[600] leading-[16px]'>contact@atmnha.vn</span>
                </div>
              </div>
              <div className='flex items-center space-x-[8px]'>
                <TiLocation className='min-h-[18px] min-w-[18px] ' />
                <div className='flex flex-col'>
                  <span className='text-[13px] leading-[16px]'>Địa chỉ</span>
                  <span className='text-[13px] font-[600] leading-[16px]'>
                    18 Trần Thiện Chánh, P.12, Q.10, Tp. Hồ Chính Minh
                  </span>
                </div>
              </div>
              <div className='h-[45px] w-[119px] bg-[url("/logos/ministry-of-industry-and-trade.svg")] bg-cover bg-center bg-no-repeat' />
            </div>
            <div className='flex space-x-[36px]'>
              {map(navigations, (navigation, navigationIndex) => (
                <div key={navigationIndex} className='flex flex-col space-y-[8px]'>
                  <span className='text-[16px] font-[800] uppercase leading-[20px]'>
                    {navigation.name}
                  </span>
                  <div className='text-[13px] leading-[28px]'>
                    {map(navigation.children, (navigationChild, navigationChildIndex) => (
                      <Fragment key={navigationChildIndex}>
                        <Link href={navigationChild.pathname}>
                          <a>
                            <span>{navigationChild.name}</span>
                          </a>
                        </Link>
                        <br />
                      </Fragment>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            <div className='flex flex-col space-y-[44px]'>
              <button type='button' className='flex flex-col space-y-[12px]'>
                <span className='text-[16px] font-[800] uppercase leading-[20px]'>ngôn ngữ</span>
                <div className='flex items-center space-x-[8px]'>
                  <CircleFlag countryCode='vn' width={24} height={24} />
                  <span className='text-[13px] leading-[28px]'>Tiếng Việt</span>
                  <MdOutlineArrowDropDown className='h-[20px] w-[20px]' />
                </div>
              </button>
              <div className='flex flex-col space-y-[12px]'>
                <span className='text-[16px] font-[800] uppercase leading-[20px]'>
                  Tải ứng dụng trên điện thoại
                </span>
                <div className='flex items-center space-x-[16px]'>
                  {map(stores, (store, storeIndex) => (
                    <Link key={storeIndex} href={store.href} passHref>
                      <a target='_blank' rel='noopener noreferrer'>
                        <div
                          className={`h-[32px] w-[120px] bg-cover bg-center bg-no-repeat ${store.background}`}
                        />
                      </a>
                    </Link>
                  ))}
                </div>
              </div>
              <div className='flex flex-col space-y-[12px]'>
                <span className='text-[16px] font-[800] uppercase leading-[20px]'>
                  Kết nối với chúng tôi
                </span>
                <div className='flex items-center space-x-[16px]'>
                  {map(socials, (social, socialIndex) => (
                    <Link key={socialIndex} href={social.href} passHref>
                      <a target='_blank' rel='noopener noreferrer'>
                        <div className='flex h-[32px] w-[32px] items-center justify-center rounded-full bg-[#000000]/[.2]'>
                          {social.icon}
                        </div>
                      </a>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className='mx-[60px] mt-[41px] h-[1px] bg-paper' />
          <div className='flex flex-col items-center'>
            <div className='mt-[13px] flex flex-wrap items-center justify-center px-[140px] xl:px-[60px]'>
              {map(stack, (stackItem, stackItemIndex) => (
                <Fragment key={stackItemIndex}>
                  {!isEqual(stackItemIndex, 0) && (
                    <div className='mx-[20px] h-[12px] w-[1px] bg-paper' />
                  )}
                  <Link href={stackItem.href} passHref>
                    <a target='_blank' rel='noopener noreferrer'>
                      <div className='flex h-[32px] items-center text-[13px] font-[600]'>
                        {stackItem.label}
                      </div>
                    </a>
                  </Link>
                </Fragment>
              ))}
            </div>
            <p className='mt-[16px] px-[120px] text-center text-[12px] leading-[20px] xl:px-[40px]'>
              Giấy ĐKKD số: 0316747912 Do Sở Kế hoạch và Đầu tư Thành phố Hồ Chí Minh cấp lần đầu
              ngày 15/03/2021.
              <br />
              Chịu trách nhiệm nội dung: Ông Võ Văn Tính - ® Ghi rõ nguồn &quot;atmnha.vn&quot; khi
              phát hành lại thông tin từ website này.
              <br />
              Toàn bộ quy chế, quy định giao dịch chung được đăng tải trên website áp dụng từ ngày
              15/03/2021.
            </p>
          </div>
          <div className='mt-[24px] h-[1px] bg-paper' />
          <p className='mt-[16px] text-center text-[13px] leading-[20px]'>
            Copyright © 2021 - ATMNha. All Rights Reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
